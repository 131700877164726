import React from "react";
import DefaultFeaturedMediaImage from "./DefaultFeaturedMediaImage";
import FeaturedMediaImage from "./FeaturedMediaImage";
import FeaturedMediaVideo from "./FeaturedMediaVideo";

const FeaturedMedia = (props) => {
  const { media } = props;

  if (!media) {
    return null;
  }

  const { __typename: type } = media;

  switch (type) {
    case "WpPost_Featuredmedia_FeaturedMedia_FeaturedImage":
      const { image } = media;
      if (image) {
        return <FeaturedMediaImage image={image} />;
      } else {
        return <DefaultFeaturedMediaImage />;
      }
    case "WpPost_Featuredmedia_FeaturedMedia_FeaturedVideo":
      const { mobileVideoUrl, desktopVideoUrl } = media;

      if (mobileVideoUrl && desktopVideoUrl) {
        return (
          <FeaturedMediaVideo
            mobileVideoUrl={mobileVideoUrl}
            desktopVideoUrl={desktopVideoUrl}
          />
        );
      } else {
        return <DefaultFeaturedMediaImage />;
      }
    default:
      return null;
  }
};

export default FeaturedMedia;
