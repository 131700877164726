import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './MediaCaption.module.scss';

const MediaCaption = (props) => {
  return (
    <div className={styles.captionWrap}>
      <div className={styles.content} dangerouslySetInnerHTML={{ __html: props.content }}></div>
    </div>
  );
};

MediaCaption.props = {
  content: PropTypes.string.isRequired,
};
export default MediaCaption;
