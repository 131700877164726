import React from "react";
import PropTypes from "prop-types";
import PostGridItem from "../PostGrid/PostGridItem";

import * as styles from "./RelatedPosts.module.scss";

const RelatedPosts = (props) => {
  const { posts } = props;

  return (
    <div className={styles.relatedPosts}>
      <hr />
      <div className="grid-container fluid">
        <div className={["h1", styles.title].join(" ")}>Related Articles</div>
        <div className="grid-x grid-margin-x">
          {posts.map((post) => {
            return (
              <PostGridItem
                key={post.id}
                className="cell medium-6 large-4"
                image={post.featuredImage.node.localFile}
                date={post.date}
                title={post.title}
                uri={post.uri}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

RelatedPosts.propTypes = {
  posts: PropTypes.array.isRequired,
};

export default RelatedPosts;
