import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import FeaturedMedia from '../../components/FeaturedMedia';
import PostContent from '../../components/PostContent/PostContent';
import RelatedPosts from '../../components/RelatedPosts/RelatedPosts';
import DefaultFeaturedMediaImage from '../../components/DefaultFeaturedMediaImage';
import YoastSeo from '../../components/YoastSeo';

const post = ({ data, pageContext }) => {
  const { translations } = pageContext;
  const { nextPage, previousPage, page } = data;
  const {
    title,
    date,
    uri,
    featuredMedia: { featuredMedia },
    postContent,
    relatedArticles,
    featuredImage,
    excerpt,
    databaseId,
  } = page;

  const renderFeaturedMedia = (featuredMedia) => {
    if (featuredMedia && featuredMedia.length > 0) {
      return featuredMedia.map((item, index) => (
        <FeaturedMedia key={index} media={item} pageContext={pageContext} />
      ));
    } else {
      return <DefaultFeaturedMediaImage />;
    }
  };

  return (
    <Layout
      bodyClass={`post-template-default single single-post postid-${databaseId} single-format-standard wp-embed-responsive singular has-post-thumbnail has-single-pagination showing-comments footer-top-visible customize-support`}
      translations={translations}
      pageContext={pageContext}
    >
      <YoastSeo {...page.seo} uri={uri} />

      {/* <Seo
        title={page.seo.title}
        description={page.seo.metaDesc}
        socialImage={featuredImage?.node}
        uri={uri}
        yoastSchema={page.seo.schema.raw}
      /> */}

      {renderFeaturedMedia(featuredMedia)}

      {postContent && postContent.layout && (
        <PostContent
          content={postContent.layout}
          date={date}
          nextPage={nextPage}
          previousPage={previousPage}
          pageContext={pageContext}
        />
      )}

      {relatedArticles && relatedArticles.relatedArticles && (
        <RelatedPosts posts={relatedArticles.relatedArticles} />
      )}
    </Layout>
  );
};

export const query = graphql`
  query post($id: String!, $nextPage: String, $previousPage: String) {
    page: wpPost(id: { eq: $id }) {
      ...PostContent
    }
    nextPage: wpPost(id: { eq: $nextPage }) {
      title
      uri
    }
    previousPage: wpPost(id: { eq: $previousPage }) {
      title
      uri
    }
  }
`;

export default post;
