import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './CenteredLayout.module.scss';

import ImageCell from './ImageCell';
import Video from '../Video';
import MediaCaption from './MediaCaption';
const TYPES = {
  ContentEditor: 'WpPost_Postcontent_Layout_CenteredLayout_CenteredContent_ContentEditor',
  OneImage: 'WpPost_Postcontent_Layout_CenteredLayout_CenteredContent_OneImage',
  TwoImage: 'WpPost_Postcontent_Layout_CenteredLayout_CenteredContent_TwoImages',
  OneVideo: 'WpPost_Postcontent_Layout_CenteredLayout_CenteredContent_OneVideo',
};

const CenteredLayout = (props) => {
  const { pageContext, content } = props;
  const { centeredContent } = content;

  return (
    <>
      <div className={styles.centeredLayout}>
        <div className="grid-container fluid">
          {centeredContent &&
            centeredContent.map((item, index) => {
              const { __typename: type } = item;

              switch (type) {
                case TYPES.ContentEditor:
                  if (!item.description) return null;
                  return (
                    <div
                      key={index}
                      className={['grid-x grid-margin-x align-center', styles.textBlockMargin].join(
                        ' ',
                      )}
                    >
                      <div className="cell medium-8 large-6">
                        <div className={styles.contentWrapper}>
                          <div
                            className={styles.content}
                            dangerouslySetInnerHTML={{
                              __html: item.description,
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  );
                case TYPES.OneImage:
                  if (item.image1 && item.image1.localFile) {
                    return (
                      <div
                        key={index}
                        className={[
                          'grid-x grid-margin-x align-center',
                          styles.imageBlockMargin,
                        ].join(' ')}
                      >
                        <div className="cell medium-8 large-6">
                          <ImageCell image={item.image1.localFile} />
                          <MediaCaption content={item.image1Caption} />
                        </div>
                      </div>
                    );
                  }
                case TYPES.TwoImage:
                  const {
                    image1: { localFile: leftImage },
                    image2: { localFile: rightImage },
                  } = item;
                  return (
                    <div
                      key={index}
                      className={[
                        'grid-x grid-margin-x align-center',
                        styles.imageBlockMargin,
                        styles.doubleImageWrap,
                      ].join(' ')}
                    >
                      {/* <div className="cell small-6 medium-4 large-5">
                        <ImageCell image={leftImage} />
                      </div>
                      <div className="cell small-6 medium-4 large-5">
                        <ImageCell image={rightImage} />
                      </div> */}
                      <div
                        className={['cell small-6 medium-4 large-3', styles.leftImageWrap].join(
                          ' ',
                        )}
                      >
                        <ImageCell image={leftImage} />
                        <MediaCaption content={item.image1Caption} />
                      </div>
                      <div
                        className={['cell small-6 medium-4 large-3', styles.rightImageWrap].join(
                          ' ',
                        )}
                      >
                        <ImageCell image={rightImage} />
                        <MediaCaption content={item.image2Caption} />
                      </div>
                    </div>
                  );
                case TYPES.OneVideo:
                  const { desktopVideoUrl, mobileVideoUrl } = item;
                  return (
                    <div
                      key={index}
                      className={[
                        'grid-x grid-margin-x align-center',
                        styles.imageBlockMargin,
                      ].join(' ')}
                    >
                      <div className="cell medium-8 large-6">
                        <Video desktopVideoUrl={desktopVideoUrl} mobileVideoUrl={mobileVideoUrl} />
                        <MediaCaption content={item.videoCaption} />
                      </div>
                    </div>
                  );
                default:
                  return null;
              }
            })}
        </div>
      </div>
    </>
  );
};

CenteredLayout.PropTypes = {
  content: PropTypes.object.isRequired,
};

export default CenteredLayout;
