import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import FullWidthLayout from './FullWidthLayout';
import TwoColumnLayout from './TwoColumnLayout';
import CenteredLayout from './CenteredLayout';
import { Localise, Keys } from '../../utils/translations';
import iconLeft from '../../assets/svg/arrow-left.svg';
import iconRight from '../../assets/svg/arrow-right.svg';

import * as styles from './PostContent.module.scss';

const PostContent = (props) => {
  const { content, pageContext } = props;

  const { languageCode } = pageContext;

  const hasContentSectionOnLeft = (data) => {
    const { column1Content } = data;

    for (let index = 0; index < column1Content.length; index++) {
      if (
        column1Content[index].__typename ===
        'WpPost_Postcontent_Layout_TwoColumnLayout_Column1Content_ContentEditor'
      ) {
        return true;
      }
    }

    return false;
  };

  let firstContentSectionRendered = false;

  return (
    <div className={styles.postContent}>
      {content.map((layout, index) => {
        const { __typename: type } = layout;
        switch (type) {
          case 'WpPost_Postcontent_Layout_FullWidthLayout':
            return <FullWidthLayout key={index} content={layout} pageContext={pageContext} />;
          case 'WpPost_Postcontent_Layout_TwoColumnLayout':
            let isFirstContentSection = false;

            if (!firstContentSectionRendered) {
              isFirstContentSection = hasContentSectionOnLeft(layout);
              firstContentSectionRendered = true;
            }

            return (
              <TwoColumnLayout
                key={index}
                content={layout}
                date={props.date}
                nextPage={props.nextPage}
                previousPage={props.previousPage}
                isFirstContentSection={isFirstContentSection}
                pageContext={pageContext}
              />
            );
          case 'WpPost_Postcontent_Layout_CenteredLayout':
            return <CenteredLayout key={index} content={layout} pageContext={pageContext} />;
          default:
            return null;
        }
      })}
      <div className={styles.navLinks}>
        <div className="grid-container fluid">
          <div className="grid-y">
            {props.previousPage && (
              <div className="cell">
                <Link
                  to={props.previousPage.uri}
                  className={['button hollow', styles.previousLink].join(' ')}
                >
                  <img className="nav-svg-icon icon-prev" src={iconLeft} width={26} alt="" />{' '}
                  {Localise(Keys.PREVIOUS, languageCode)}
                </Link>
              </div>
            )}
            {props.nextPage && (
              <div className="cell">
                <Link
                  to={props.nextPage.uri}
                  className={['button hollow', styles.nextLink].join(' ')}
                >
                  {Localise(Keys.NEXT, languageCode)}{' '}
                  <img className="nav-svg-icon icon-next" src={iconRight} width={26} alt="" />
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

PostContent.propTypes = {
  content: PropTypes.array.isRequired,
  date: PropTypes.string.isRequired,
  nextPage: PropTypes.object,
  previousPage: PropTypes.object,
  pageContext: PropTypes.object.isRequired,
};

export default PostContent;
